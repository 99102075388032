<template>
  <v-snackbar :color="notificationColor" app content-class="pa-0" right top rounded transition="v-scale-transition" timeout="-1" class="pa-0" v-model="snackbar"
>
<v-list  :color="notificationColor">
      <v-subheader>
        {{ notification.title }}</v-subheader>
        <v-list-item
     @click.stop="goToNotificationLink()"
        >
          <v-list-item-avatar>
            <v-icon dark left size="40">>
              {{ notificationIcon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ limitSize(notification.message, 35) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>

              <v-btn color="white" text small rounded class="text-capitalize" @click.stop="setSnackbarFalse">
                Dismiss
              </v-btn>
          </v-list-item-action>
        </v-list-item>
    </v-list>
  </v-snackbar>
</template>
<script type="text/javascript">
import string from "@/mixins/string";
export default {
  mixins: [string],
  data() {
    return {};
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.getters["notifications/snackOptions"].snackbar;
      },
      set(value) {
        this.$store.dispatch("notifications/snackOptionsData", { snackbar: value });
      }
    },
    notification: {
      get() {
        return this.$store.getters["notifications/snackOptions"].message;
      },
      set() {}
    },
   notificationClass() {
      switch (this.notification?.subjectModel?.commentable_type) {
        case "App\\Models\\Workforce":
          return "workforce";
        case "App\\Models\\User":
          return "user";
        case "App\\Models\\Employee":
          return "employee";
        default:
          return "employee";
      }
    },
   notificationColor() {
     let notificationClass = this.notificationClass;
     switch (notificationClass) {
       case "employee":
         return "light-blue darken-1";
       default:
         return "primary";
     }
    },
   notificationIcon() {
     let notificationClass = this.notificationClass;
     switch (notificationClass) {
       case "user":
         return "mdi-account-circle";
       case "employee":
         return "mdi-account-hard-hat";
       default:
         return "mdi-lan";
     }
    },
  },
  methods: {
    goToNotificationLink() {
      this.$router.push({
        name: "show-" + this.notificationClass,
        params: {id: this.notification?.subjectModel?.commentable_id}
      });
    },
    setSnackbarFalse() {
      this.$store.dispatch("notifications/snackOptionsData", { snackbar: false });
    }
  }
};
</script>
